import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import './Compnents/assets/vendor/bootstrap/js/bootstrap.bundle.js'
// import 'aos/dist/aos.js';
// import 'glightbox/dist/js/glightbox.min.js';
// import 'purecounter/dist/purecounter_vanilla.js';
// import 'swiper/swiper-bundle.min.js';
// import 'isotope-layout/dist/isotope.pkgd.min.js';
// import 'php-email-form/validate.js';
// import './assets/js/main.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
