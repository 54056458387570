import React from 'react';
import method from "../assets/img/png-removebg-preview.png"

function Methodlogy() {
  return (
    <section id="services" className="services sections-bg1">
      <div className="container" data-aos="fade-up">
     <div className="section-header">
        <h2>Methodology</h2>
        <p>At Ryientsoft Technology, our methodology blends innovative technology with industry expertise to deliver cutting-edge solutions. We emphasize agile development practices, ensuring flexibility and responsiveness to client needs. Our approach focuses on robust collaboration, leveraging advanced tools and frameworks to achieve scalable and efficient outcomes.
        </p>
      </div>
      <div className="image-container">
        <img src={method} alt= ""/>
      </div>
      
      </div>
   </section>
  );
}

export default Methodlogy;
