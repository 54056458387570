import React from 'react';
import Blog1Image from '../assets/img/blog/blog-1.jpg';
import Blog2Image from '../assets/img/blog/blog-2.jpg';
import Blog3Image from '../assets/img/blog/blog-3.jpg';
import Blog4Image from '../assets/img/blog/blog-4.jpg';
import Blog5Image from '../assets/img/blog/blog-5.jpg';
import Blog6Image from '../assets/img/blog/blog-6.jpg';

import BlogAuthor1 from '../assets/img/blog/blog-author.jpg';
import BlogAuthor2 from '../assets/img/blog/blog-author-2.jpg';
import BlogAuthor3 from '../assets/img/blog/blog-author-3.jpg';
import BlogAuthor4 from '../assets/img/blog/blog-author-4.jpg';
import BlogAuthor5 from '../assets/img/blog/blog-author-5.jpg';
import BlogAuthor6 from '../assets/img/blog/blog-author-6.jpg';
import { Link } from 'react-router-dom';

function Blog() {
  return (
    <>
      

      {/* <!-- ======= Blog Section ======= --> */}
      <section id="blog" className="blog bg-whitesmoke">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Blog</h2>
            <p>
            At Ryientsoft Technology, we are passionate about leveraging the latest advancements in technology to 
            drive innovation and success for our clients. Our blog is your go-to resource for insights, trends, and 
            updates in the ever-evolving world of technology. From deep dives into emerging technologies and software
             development best practices to case studies showcasing our successful projects, we aim to provide valuable
              content that empowers businesses and tech enthusiasts alike.
            </p>
          </div>

          <div className="row gy-4 posts-list">
            <div className="col-xl-4 col-md-6">
              <article>
                <div className="post-img">
                  <img src={Blog1Image} alt="" className="img-fluid" />
                </div>
                <p className="post-category">Politics</p>
                <h2 className="title">
                  <Link className="text-decoration-none" to="/blog-details">
                    Power Play: Politics Unveiled
                  </Link>
                </h2>
                <div className="d-flex align-items-center">
                  <img
                    src={BlogAuthor1}
                    alt=""
                    className="post-author-img flex-shrink-0"
                  />
                  <div className="post-meta">
                    <div className="post-author-list">Maria Doe</div>
                    <p className="post-date">
                      <time dateTime="2022-01-01">Jan 1, 2022</time>
                    </p>
                  </div>
                </div>
              </article>
            </div>
            {/* <!-- End post list item --> */}

            <div className="col-xl-4 col-md-6">
              <article>
                <div className="post-img">
                  <img src={Blog2Image} alt="" className="img-fluid" />
                </div>
                <p className="post-category">Sports</p>
                <h2 className="title">
                  <Link className="text-decoration-none" to="/blog-details">
                    Game On: Exploring the World of Sports
                  </Link>
                </h2>
                <div className="d-flex align-items-center">
                  <img
                    src={BlogAuthor2}
                    alt=""
                    className="post-author-img flex-shrink-0"
                  />
                  <div className="post-meta">
                    <div className="post-author-list">Allisa Mayer</div>
                    <p className="post-date">
                      <time dateTime="2022-06-05">Jun 5, 2022</time>
                    </p>
                  </div>
                </div>
              </article>
            </div>
            {/* <!-- End post list item --> */}

            <div className="col-xl-4 col-md-6">
              <article>
                <div className="post-img">
                  <img src={Blog3Image} alt="" className="img-fluid" />
                </div>
                <p className="post-category">Entertainment</p>
                <h2 className="title">
                  <Link className="text-decoration-none" to="/blog-details">
                    Showtime Chronicles: Unveiling the Glamour
                  </Link>
                </h2>
                <div className="d-flex align-items-center">
                  <img
                    src={BlogAuthor3}
                    alt=""
                    className="post-author-img flex-shrink-0"
                  />
                  <div className="post-meta">
                    <div className="post-author-list">Mark Dower</div>
                    <p className="post-date">
                      <time dateTime="2022-06-22">Jun 22, 2022</time>
                    </p>
                  </div>
                </div>
              </article>
            </div>
            {/* <!-- End post list item --> */}

            <div className="col-xl-4 col-md-6">
              <article>
                <div className="post-img">
                  <img src={Blog4Image} alt="" className="img-fluid" />
                </div>
                <p className="post-category">Sports</p>
                <h2 className="title">
                  <Link className="text-decoration-none" to="/blog-details">
                    Game On: Sports in Action
                  </Link>
                </h2>
                <div className="d-flex align-items-center">
                  <img
                    src={BlogAuthor4}
                    alt=""
                    className="post-author-img flex-shrink-0"
                  />
                  <div className="post-meta">
                    <div className="post-author-list">Lisa Neymar</div>
                    <p className="post-date">
                      <time dateTime="2022-06-30">Jun 30, 2022</time>
                    </p>
                  </div>
                </div>
              </article>
            </div>
            {/* <!-- End post list item --> */}

            <div className="col-xl-4 col-md-6">
              <article>
                <div className="post-img">
                  <img src={Blog5Image} alt="" className="img-fluid" />
                </div>
                <p className="post-category">Politics</p>
                <h2 className="title">
                  <Link className="text-decoration-none" to="/blog-details">
                    Power Play: Navigating the Political Landscape
                  </Link>
                </h2>
                <div className="d-flex align-items-center">
                  <img
                    src={BlogAuthor5}
                    alt=""
                    className="post-author-img flex-shrink-0"
                  />
                  <div className="post-meta">
                    <div className="post-author-list">Denis Peterson</div>
                    <p className="post-date">
                      <time dateTime="2022-01-30">Jan 30, 2022</time>
                    </p>
                  </div>
                </div>
              </article>
            </div>
            {/* <!-- End post list item --> */}

            <div className="col-xl-4 col-md-6">
              <article>
                <div className="post-img">
                  <img src={Blog6Image} alt="" className="img-fluid" />
                </div>
                <p className="post-category">Entertainment</p>
                <h2 className="title">
                  <Link className="text-decoration-none" to="/blog-details">
                    Lights, Camera, Action: Inside the Realm of Entertainment
                  </Link>
                </h2>
                <div className="d-flex align-items-center">
                  <img
                    src={BlogAuthor6}
                    alt=""
                    className="post-author-img flex-shrink-0"
                  />
                  <div className="post-meta">
                    <div className="post-author-list">Mika Lendon</div>
                    <p className="post-date">
                      <time dateTime="2022-02-14">Feb 14, 2022</time>
                    </p>
                  </div>
                </div>
              </article>
            </div>
            {/* <!-- End post list item --> */}
          </div>
          {/* <!-- End blog posts list --> */}

          {/* 
          <div className="blog-pagination">
            <ul className="justify-content-center">
              <li><a href="#">1</a></li>
              <li className="active"><a href="#">2</a></li>
              <li><a href="#">3</a></li>
            </ul>
          </div>
          */}
          {/* <!-- End blog pagination --> */}
        </div>
      </section>
      {/* <!-- End Blog Section --> */}
    </>
  );
}

export default Blog;
