import React from 'react'
import { Link } from "react-router-dom";
import heroImage from '../assets/img/language.png'

function Hero() {
  const scrollToContent = () => {
    window.scrollTo({
      top: 550,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <section id="hero" className="hero">
        <div className="container position-relative">
          <div className="row " data-aos="fade-in">
            <div className="col-lg-6 order-1 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
              <h2>PROFESSIONAL , PERSONAL <span>SOLUTIONS</span></h2>
              <p className="text">OUR CREATIVITY IS THE PRODUCT OF YOUR IMAGINATION.</p>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <Link to="#" className="btn-get-started text-decoration-none" onClick={scrollToContent}>Get Started</Link>
                {/* <Link to="https://www.youtube.com/watch?v=LXb3EKWsInQ" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Watch Video</span></Link> */}
              </div>
            </div>
            <div className="col-lg-6 order-2 order-lg-2 text-center">
              <img src={heroImage}  className="img-fluid3" alt="" data-aos="zoom-out" data-aos-delay="100"/>
            </div>
          </div>
        </div>
        {/* Our services start  */}
        <div className="position-relative py-5">
          <div className="container position-relative">
            <div className="row gy-4 mt-3">
              <div className="col-lg-12">
               
                <h1 className="text-center text-black"><span>Tailored Solutions</span> </h1>
                <p className="text-center ">At Ryientsoft Technology, we provide a wide array of web development services designed to meet the varying needs of businesses in today's digital world. 
                  Our offerings include custom website creation, e-commerce solutions, responsive design, 
                  CMS development, and web application development, all aimed at establishing a robust 
                  online presence for our clients. 
                   </p>
              </div>
              <div className="col-xl-4 col-md-6"  data-aos="fade-up" data-aos-delay="100">
                <div className="icon-box">
                  <div className="icon"><i className="bi bi-code"></i></div>
                  <h4 className="title"><Link to="" className="stretched-link text-decoration-none">Web Development</Link></h4>
                  <p className="text-light"> Our web development team crafts high-performing, visually stunning websites tailored to your business needs. From responsive designs to robust backend solutions, we ensure your online presence is both effective and engaging.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-md-6"  data-aos="fade-up" data-aos-delay="100">
                <div className="icon-box">
                  <div className="icon"><i className="bi bi-android2"></i></div>
                  <h4 className="title"><Link to="" className="stretched-link text-decoration-none">App Development</Link></h4>
                  <p className="text-light">Transform your ideas into powerful mobile applications with our app development expertise. We create user-friendly, high-performance apps for iOS and Android, designed to deliver an exceptional user experience and drive your business forward. 
                    
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                <div className="icon-box">
                  <div className="icon"><i className="bi bi-journal-code"></i></div>
                  <h4 className="title"><Link to="" className="stretched-link text-decoration-none">Figma Design</Link></h4>
                  <p className="text-light">
                  Elevate your digital projects with our Figma design services. Our designers leverage Figma's collaborative tools to create intuitive, aesthetically pleasing interfaces that bring your vision to life and enhance user interaction.
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                <div className="icon-box">
                  <div className="icon"><i className="bi bi-geo-alt"></i></div>
                  <h4 className="title"><Link to="" className="stretched-link text-decoration-none">Data, Analytics & AI</Link></h4>
                  <p className="text-light">
                   Unlock the potential of your data with our advanced data analysis and AI solutions. We help you gain actionable insights, automate processes, and make data-driven decisions to stay ahead in a competitive market. 
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                <div className="icon-box">
                  <div className="icon"><i className="bi bi-command"></i></div>
                  <h4 className="title"><Link to="" className="stretched-link text-decoration-none">Cloud & Devops </Link></h4>
                  <p className="text-light">
                  Streamline your operations with our cloud and DevOps services. We offer scalable cloud solutions and efficient DevOps practices to enhance your infrastructure, improve deployment speed, and ensure robust security.
                  
                  </p>
                </div>
              </div>

              <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                <div className="icon-box">
                  <div className="icon"><i className="bi bi-android"></i></div>
                  <h4 className="title"><Link to="" className="stretched-link text-decoration-none">Product Development</Link></h4>
                  <p className="text-light">
                  From concept to market-ready, our product development team is with you every step of the way. We combine innovative thinking with technical expertise to create products that meet your business goals and delight your customers. 
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* Our services end  */}
      </section >
    </>
  )
}

export default Hero