import React, { useState } from 'react';
import { Link } from "react-router-dom";
import RY4 from "../assets/img/ry4.webp";

function Header(data) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <header id="header" className="header d-flex align-items-center">
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
          <Link to="/" className="logo d-flex align-items-center">
            <img src={RY4} alt="" />
            {/* <h1>Ryientsoft Technologies</h1> */}
          </Link>
          <nav id="navbar" className={`navbar ${isMenuOpen ? 'navbar-mobile' : ''}`}>
            <ul>
              <li><Link className={data.data === "home" ? "text-decoration-none active" : "text-decoration-none"} to="/" onClick={closeMenu}>Home</Link></li>
              <li><Link className={data.data === "about" ? "text-decoration-none active" : "text-decoration-none"} to="/about" onClick={closeMenu}>About</Link></li>
              <li><Link className={data.data === "domain-services" ? "text-decoration-none active" : "text-decoration-none"} to="/domainservices" onClick={closeMenu}>Domain Services</Link></li>
              <li><Link className={data.data === "digital-marketing" ? "text-decoration-none active" : "text-decoration-none"} to="/digitalmarketing" onClick={closeMenu}>Digital Marketing</Link></li>
              <li><Link className={data.data === "portfolio" ? "text-decoration-none active" : "text-decoration-none"} to="/portfolio" onClick={closeMenu}>Portfolio</Link></li>
              {/* <li><Link className={data.data === "blog" ? "text-decoration-none active" : "text-decoration-none"} to="/blog" onClick={closeMenu}>Blog</Link></li> */}
              <li><Link className={data.data === "contact" ? "text-decoration-none active" : "text-decoration-none"} to="/contact" onClick={closeMenu}>Contact</Link></li>
            </ul>
          </nav>
          <i className={`mobile-nav-toggle bi ${isMenuOpen ? 'bi-x' : 'bi-list'}`} onClick={handleMenuToggle}></i>
        </div>
      </header>
    </>
  );
}

export default Header;
