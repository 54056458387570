import React from "react";
import { Link } from "react-router-dom";
import Blog1Image from "../assets/img/blog/blog-1.jpg";
import BlogAuthorImage from "../assets/img/blog/blog-author.jpg";
import BlogInsidePostImage from "../assets/img/blog/blog-inside-post.jpg";
import Comment1Image from "../assets/img/blog/comments-1.jpg";
import Comment2Image from "../assets/img/blog/comments-2.jpg";
import Comment3Image from "../assets/img/blog/comments-3.jpg";
import Comment4Image from "../assets/img/blog/comments-4.jpg";
import Comment5Image from "../assets/img/blog/comments-5.jpg";
import Comment6Image from "../assets/img/blog/comments-6.jpg";
import BlogRecent1Image from "../assets/img/blog/blog-recent-1.jpg";
import BlogRecent2Image from "../assets/img/blog/blog-recent-2.jpg";
import BlogRecent3Image from "../assets/img/blog/blog-recent-3.jpg";
import BlogRecent4Image from "../assets/img/blog/blog-recent-4.jpg";
import BlogRecent5Image from "../assets/img/blog/blog-recent-5.jpg";

function BlogDetails() {
  return (
    <>
      <main id="main">
        {/* <!-- ======= Breadcrumbs ======= --> */}
        <div class="breadcrumbs">
          <div class="page-header d-flex align-items-center">
            <div class="container position-relative">
              <div class="row d-flex justify-content-center">
                <div class="col-lg-6 text-center">
                  <h2>Blog Details</h2>
                  <p>
                    Welcome to our blog! As a premier software provider, we are
                    dedicated to empowering businesses with cutting-edge
                    technology solutions. Whether you're a startup or a
                    well-established enterprise, our software is designed to
                    streamline operations, boost productivity, and drive growth.
                    In this blog, we'll share insights, tips, and success
                    stories to help you leverage our software to its fullest
                    potential.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <nav>
            <div class="container">
              <ol>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Blog Details</li>
              </ol>
            </div>
          </nav>
        </div>
        {/* <!-- End Breadcrumbs --> */}

        {/* <!-- ======= Blog Details Section ======= --> */}
        <section id="blog" class="blog">
          <div class="container" data-aos="fade-up">
            <div class="row g-5">
              <div class="col-lg-8">
                <article class="blog-details">
                  <div class="post-img">
                    <img src={Blog1Image} alt="" class="img-fluid" />
                  </div>
                  <h2 class="title">Power Play: Politics Unveiled</h2>
                  <div class="meta-top">
                    <ul>
                      <li class="d-flex align-items-center">
                        <i class="bi bi-person"></i>{" "}
                        <Link to="/blog-details">John Doe</Link>
                      </li>
                      <li class="d-flex align-items-center">
                        <i class="bi bi-clock"></i>{" "}
                        <Link to="/blog-details">
                          <time datetime="2020-01-01">Jan 1, 2022</time>
                        </Link>
                      </li>
                      <li class="d-flex align-items-center">
                        <i class="bi bi-chat-dots"></i>{" "}
                        <Link to="/blog-details">12 Comments</Link>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- End meta top --> */}

                  <div class="content">
                    <p>
                      Politics involves the activities associated with
                      governance and decision-making within societies. It
                      encompasses the processes through which individuals and
                      groups negotiate, compete, and collaborate to influence
                      public policies and the distribution of resources.
                    </p>
                    <p>
                      Political systems can vary widely, from democracies where
                      citizens have a direct or representative role in
                      decision-making, to autocracies where power is
                      concentrated in a single authority.
                    </p>
                    <blockquote>
                      <p>
                        Key elements of politics include elections, political
                        parties, ideologies, and institutions such as
                        legislatures and governments. Political ideologies shape
                        beliefs about the role of government, justice, and
                        individual rights. Major political issues often revolve
                        around economic policies, social welfare, civil rights,
                        foreign affairs, and environmental concerns. The study
                        of politics involves examining power dynamics, policy
                        formulation, and the impact of decisions on society.
                      </p>
                    </blockquote>
                    <p>
                      Political discourse and conflicts can be intense and are
                      often driven by differing interests, values, and
                      interpretations of societal priorities.
                    </p>

                    <h3>
                      {" "}
                      Understanding politics requires analyzing historical
                      contexts, socio-economic factors, and the evolving
                      dynamics of power and influence in local, national, and
                      international contexts.
                    </h3>
                    <p></p>
                    <img src={BlogInsidePostImage} class="img-fluid" alt="" />

                    <h3>
                      {" "}
                      In summary, politics is a multifaceted field that
                      influences every aspect of human life, from local
                      governance to international relations, reflecting diverse
                      ideologies and power dynamics
                    </h3>
                    <p>
                      Global politics involves interactions between states,
                      international organizations, and non-state actors on
                      issues such as diplomacy, trade, security, and human
                      rights.
                    </p>
                    <p>
                      It often centers around complex alliances, conflicts, and
                      negotiations that influence policies and events on a
                      global scale.
                    </p>
                  </div>
                  {/* <!-- End post content --> */}

                  <div class="meta-bottom">
                    <i class="bi bi-folder"></i>
                    <ul class="cats">
                      <li>
                        <Link to="#">Business</Link>
                      </li>
                    </ul>

                    <i class="bi bi-tags"></i>
                    <ul class="tags">
                      <li>
                        <Link to="#">Creative</Link>
                      </li>
                      <li>
                        <Link to="#">Tips</Link>
                      </li>
                      <li>
                        <Link to="#">Marketing</Link>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- End meta bottom --> */}
                </article>
                {/* <!-- End blog post --> */}

                <div class="post-author d-flex align-items-center">
                  <img
                    src={BlogAuthorImage}
                    class="rounded-circle flex-shrink-0"
                    alt=""
                  />
                  <div>
                    <h4>Jane Smith</h4>
                    <div class="social-links">
                      <Link to="https://twitters.com/#">
                        <i class="bi bi-twitter"></i>
                      </Link>
                      <Link to="https://facebook.com/#">
                        <i class="bi bi-facebook"></i>
                      </Link>
                      <Link to="https://instagram.com/#">
                        <i class="biu bi-instagram"></i>
                      </Link>
                    </div>
                    <p></p>
                  </div>
                </div>
                {/* <!-- End post author --> */}

                <div class="comments">
                  <h4 class="comments-count">8 Comments</h4>
                  <div id="comment-1" class="comment">
                    <div class="d-flex">
                      <div class="comment-img">
                        <img src={Comment1Image} alt="" />
                      </div>
                      <div>
                        <h5>
                          <Link to="">Georgia Reader</Link>{" "}
                          <Link to="#" class="reply">
                            <i class="bi bi-reply-fill"></i> Reply
                          </Link>
                        </h5>
                        <time datetime="2020-01-01">01 Jan,2022</time>
                        <p></p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End comment #1 --> */}

                  <div id="comment-2" class="comment">
                    <div class="d-flex">
                      <div class="comment-img">
                        <img src={Comment2Image} alt="" />
                      </div>
                      <div>
                        <h5>
                          <Link to="">Aron Alvarado</Link>{" "}
                          <Link to="#" class="reply">
                            <i class="bi bi-reply-fill"></i> Reply
                          </Link>
                        </h5>
                        <time datetime="2020-01-01">01 Jan,2022</time>
                        <p></p>
                      </div>
                    </div>

                    <div id="comment-reply-1" class="comment comment-reply">
                      <div class="d-flex">
                        <div class="comment-img">
                          <img src={Comment3Image} alt="" />
                        </div>
                        <div>
                          <h5>
                            <Link to="">Lynda Small</Link>{" "}
                            <Link to="#" class="reply">
                              <i class="bi bi-reply-fill"></i> Reply
                            </Link>
                          </h5>
                          <time datetime="2020-01-01">01 Jan,2022</time>
                          <p></p>
                        </div>
                      </div>

                      <div id="comment-reply-2" class="comment comment-reply">
                        <div class="d-flex">
                          <div class="comment-img">
                            <img src={Comment4Image} alt="" />
                          </div>
                          <div>
                            <h5>
                              <Link to="">Sianna Ramsay</Link>{" "}
                              <Link to="#" class="reply">
                                <i class="bi bi-reply-fill"></i> Reply
                              </Link>
                            </h5>
                            <time datetime="2020-01-01">01 Jan,2022</time>
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="comment-3" class="comment">
                    <div class="d-flex">
                      <div class="comment-img">
                        <img src={Comment5Image} alt="" />
                      </div>
                      <div>
                        <h5>
                          <Link to="">Nolan Davidson</Link>{" "}
                          <Link to="#" class="reply">
                            <i class="bi bi-reply-fill"></i> Reply
                          </Link>
                        </h5>
                        <time datetime="2020-01-01">01 Jan,2022</time>
                        <p></p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End comment #3 --> */}

                  <div id="comment-4" class="comment">
                    <div class="d-flex">
                      <div class="comment-img">
                        <img src={Comment6Image} alt="" />
                      </div>
                      <div>
                        <h5>
                          <Link to="">Kay Duggan</Link>{" "}
                          <Link to="#" class="reply">
                            <i class="bi bi-reply-fill"></i> Reply
                          </Link>
                        </h5>
                        <time datetime="2020-01-01">01 Jan,2022</time>
                        <p></p>
                      </div>
                    </div>
                  </div>
                  <div class="reply-form">
                    <h4>Leave a Reply</h4>
                    <p>
                      Your email address will not be published. Required fields
                      are marked *{" "}
                    </p>
                    <form action="">
                      <div class="row">
                        <div class="col-md-6 form-group">
                          <input
                            name="name"
                            type="text"
                            class="form-control"
                            placeholder="Your Name*"
                          />
                        </div>
                        <div class="col-md-6 form-group">
                          <input
                            name="email"
                            type="text"
                            class="form-control"
                            placeholder="Your Email*"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col form-group">
                          <input
                            name="website"
                            type="text"
                            class="form-control"
                            placeholder="Your Website"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col form-group">
                          <textarea
                            name="comment"
                            class="form-control"
                            placeholder="Your Comment*"
                          ></textarea>
                        </div>
                      </div>
                      <button type="submit" class="btn btn-primary">
                        Post Comment
                      </button>
                    </form>
                  </div>
                </div>
                {/* <!-- End blog comments --> */}
              </div>
              <div class="col-lg-4">
                <div class="sidebar">
                  <div class="sidebar-item search-form">
                    <h3 class="sidebar-title">Search</h3>
                    <form action="" class="mt-3">
                      <input type="text" />
                      <button type="submit">
                        <i class="bi bi-search"></i>
                      </button>
                    </form>
                  </div>
                  {/* <!-- End sidebar search formn--> */}

                  <div class="sidebar-item categories">
                    <h3 class="sidebar-title">Categories</h3>
                    <ul class="mt-3">
                      <li>
                        <Link to="#">
                          General <span>(25)</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          Lifestyle <span>(12)</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          Travel <span>(5)</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          Design <span>(22)</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          Creative <span>(8)</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          Educaion <span>(14)</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- End sidebar categories--> */}

                  <div class="sidebar-item recent-posts">
                    <h3 class="sidebar-title">Recent Posts</h3>

                    <div class="mt-3">
                      <div class="post-item mt-3">
                        <img src={BlogRecent1Image} alt="" />
                        <div>
                          <h4>
                            <Link to="/blog-details">
                              The Rise of Remote Work
                            </Link>
                          </h4>
                          <time datetime="2020-01-01">Jan 1, 2020</time>
                        </div>
                      </div>
                      {/* <!-- End recent post item--> */}

                      <div class="post-item">
                        <img src={BlogRecent2Image} alt="" />
                        <div>
                          <h4>
                            <Link to="/blog-details">
                              Sustainable Living Practices
                            </Link>
                          </h4>
                          <time datetime="2020-01-01">Jan 1, 2020</time>
                        </div>
                      </div>
                      {/* <!-- End recent post item--> */}

                      <div class="post-item">
                        <img src={BlogRecent3Image} alt="" />
                        <div>
                          <h4>
                            <Link to="/blog-details">
                              Artificial Intelligence in Everyday Life
                            </Link>
                          </h4>
                          <time datetime="2020-01-01">Jan 1, 2020</time>
                        </div>
                      </div>
                      {/* <!-- End recent post item--> */}

                      <div class="post-item">
                        <img src={BlogRecent4Image} alt="" />
                        <div>
                          <h4>
                            <Link to="/blog-details">
                              Impact of Social Media on Society
                            </Link>
                          </h4>
                          <time datetime="2020-01-01">Jan 1, 2020</time>
                        </div>
                      </div>
                      {/* <!-- End recent post item--> */}

                      <div class="post-item">
                        <img src={BlogRecent5Image} alt="" />
                        <div>
                          <h4>
                            <Link to="/blog-details">
                              Diversity and Inclusion in the Workplace
                            </Link>
                          </h4>
                          <time datetime="2020-01-01">Jan 1, 2020</time>
                        </div>
                      </div>
                      {/* <!-- End recent post item--> */}
                    </div>
                  </div>
                  {/* <!-- End sidebar recent posts--> */}

                  <div class="sidebar-item tags">
                    <h3 class="sidebar-title">Tags</h3>
                    <ul class="mt-3">
                      <li>
                        <Link to="#">App</Link>
                      </li>
                      <li>
                        <Link to="#">IT</Link>
                      </li>
                      <li>
                        <Link to="#">Business</Link>
                      </li>
                      <li>
                        <Link to="#">Mac</Link>
                      </li>
                      <li>
                        <Link to="#">Design</Link>
                      </li>
                      <li>
                        <Link to="#">Office</Link>
                      </li>
                      <li>
                        <Link to="#">Creative</Link>
                      </li>
                      <li>
                        <Link to="#">Studio</Link>
                      </li>
                      <li>
                        <Link to="#">Smart</Link>
                      </li>
                      <li>
                        <Link to="#">Tips</Link>
                      </li>
                      <li>
                        <Link to="#">Marketing</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default BlogDetails;
