import React, { useEffect, useRef } from 'react';
import statusImage from "../assets/img/programming.webp"

function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
}
function Counter() {
  const pureCounterRef = useRef(null);
  
  useEffect(() => {
    loadScript(`${process.env.PUBLIC_URL}/assets/vendor/purecounter/purecounter_vanilla.js`)
      .then(() => {
        if (window.PureCounter) {
          pureCounterRef.current = new window.PureCounter();
        } else {
          console.error('PureCounter is not available on the window object');
        }
      })
      .catch(err => {
        console.error('Failed to load PureCounter script:', err);
      });
  }, []);
  return (
    <>
      <section id="stats-counter" className="stats-counter">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4 align-items-center">
            <div className="col-lg-6">
              <img src={statusImage} alt="" className="image" />
            </div>

            <div className="col-lg-6">
              <div className="stats-item d-flex align-items-center">
                <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" className="purecounter"></span>
                <p><strong>Happy Clients</strong> who achieved remarkable success</p>
              </div>
              {/* End Stats Item */}

              <div className="stats-item d-flex align-items-center">
                <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" className="purecounter"></span>
                <p><strong>Projects</strong> with Outstanding Results</p>
              </div>
              {/* End Stats Item */}

              <div className="stats-item d-flex align-items-center">
                <span data-purecounter-start="0" data-purecounter-end="453" data-purecounter-duration="1" className="purecounter"></span>
                <p><strong>Hours Of Support</strong> with Exceptional Service</p>
              </div>
              {/* End Stats Item */}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Counter