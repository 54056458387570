import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import clientImage1 from "../assets/img/portfolio/optelma.png"
import clientImage2 from "../assets/img/portfolio/Vocadd.png"
import clientImage3 from "../assets/img/portfolio/gerencia.png"
import clientImage4 from "../assets/img/portfolio/EcoFNB.jpeg"
import clientImage5 from "../assets/img/portfolio/Event.webp"

function Clients() {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    spaceBetween: 80
  };
  return (
    <div className="container" data-aos="zoom-out">
    <Slider {...settings}>
      <div className="slide"><img src={clientImage1} className="img-fluid1" alt="" /></div>
      <div className="slide"><img src={clientImage2} className="img-fluid1" alt="" /></div>
      <div className="slide"><img src={clientImage3} className="img-fluid1" alt="" /></div>
      <div className="slide"><img src={clientImage4} className="img-fluid1" alt="" /></div>
      <div className="slide"><img src={clientImage5} className="img-fluid1" alt="" /></div>
    </Slider>
    </div>
  )
}

export default Clients