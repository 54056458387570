import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

import "./App.css";
import Hero from "./Compnents/Pages/Hero";
import About from "./Compnents/Pages/About";
import Clients from "./Compnents/Pages/Clients";
import Counter from "./Compnents/Pages/Counter";
import DomainServices from "./Compnents/Pages/DomainServices.jsx";
import Testimonials from "./Compnents/Pages/Testimonials";
import Portfolio from "./Compnents/Pages/Portfolio";
import Pricing from "./Compnents/Pages/Pricing";
import Contact from "./Compnents/Pages/Contact";
import Blog from "./Compnents/Pages/Blog";
import Header from "./Compnents/Pages/Header";
import BackToTop from "./Compnents/Pages/BackToTop";
import Footer from "./Compnents/Pages/Footer";
import Technology from "./Compnents/Pages/DigitalMarketing";
import DigitalMarketing from "./Compnents/Pages/DigitalMarketing";
import Methodlogy from "./Compnents/Pages/Methodlogy.jsx"
// import GetInTouch from "./Compnents/Pages/GetInTouch.jsx"

import "./Compnents/assets/css/main.css";
import "./Compnents/assets/vendor/bootstrap/css/bootstrap.min.css";
import "./Compnents/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./Compnents/assets/vendor/aos/aos.css";
import "./Compnents/assets/vendor/glightbox/css/glightbox.min.css";
import "./Compnents/assets/vendor/swiper/swiper-bundle.min.css";
import "./Compnents/assets/vendor/bootstrap/css/bootstrap.css";
import BlogDetails from "./Compnents/Pages/BlogDetails";

function loadScript(src, position = document.head) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = false;
    script.onload = resolve;
    script.onerror = () => reject(new Error(`Script load error for ${src}`));
    position.appendChild(script);
  });
}

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  useEffect(() => {
    const styleLinks = [
      "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600;1,700&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap",
    ];
    const preconnects = [
      "https://fonts.gstatic.com",
      "https://fonts.googleapis.com",
    ];

    const basePublicPath = process.env.PUBLIC_URL;
    // console.log('PUBLIC_URL:', basePublicPath);
    const scriptsInOrder = [
      `${basePublicPath}/assets/vendor/bootstrap/js/bootstrap.bundle.min.js`,
      `${basePublicPath}/assets/vendor/aos/aos.js`,
      `${basePublicPath}/assets/vendor/glightbox/js/glightbox.min.js`,
      `${basePublicPath}/assets/vendor/purecounter/purecounter_vanilla.js`,
      `${basePublicPath}/assets/vendor/swiper/swiper-bundle.min.js`,
      `${basePublicPath}/assets/vendor/isotope-layout/isotope.pkgd.min.js`,
      `${basePublicPath}/assets/vendor/php-email-form/validate.js`,
      `${basePublicPath}/assets/js/main.js`,
    ];

    scriptsInOrder
      .reduce(
        (promise, script) => promise.then(() => loadScript(script)),
        Promise.resolve()
      )
      .then(() => console.log("All scripts loaded successfully"))
      .catch((error) => console.error("Script loading error:", error));

    preconnects.forEach((href) => {
      const link = document.createElement("link");
      link.rel = "preconnect";
      link.href = href;
      link.crossOrigin = "";
      document.head.appendChild(link);
    });

    styleLinks.forEach((href) => {
      const link = document.createElement("link");
      link.href = href;
      link.rel = "stylesheet";
      document.head.appendChild(link);
    });

    return () => {
      [...styleLinks, ...preconnects].forEach((href) => {
        const link = document.head.querySelector(`link[href="${href}"]`);
        if (link) {
          document.head.removeChild(link);
        }
      });
      scriptsInOrder.forEach((src) => {
        let script = document.head.querySelector(`script[src="${src}"]`);
        if (script) {
          document.head.removeChild(script);
        }
      });
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header data={'home'} />
              <Hero />
              {/* <About /> */}
              <Clients />
              <Counter />
              
              <Methodlogy />
             
              <Testimonials />
              {/* <Pricing /> */}
              {/* <Contact /> */}
            </>
          }
        />
        <Route
          path="/about"
          element={
            <>
              <Header data={'about'} />
              <About />
            </>
          }
        />
        <Route
          path="/blog"
          element={
            <>
              <Header data={'blog'} />
              <Blog />
            </>
          }
        />
        <Route
          path="/blog-details"
          element={
            <>
              <Header />
              <BlogDetails />
            </>
          }
        />
        <Route
          path="/domainservices"
          element={
            <>
              <Header data={'domain-services'} />
              <DomainServices />
              
            </>
          }
        />
        <Route
          path="/portfolio"
          element={
            <>
              <Header data={'portfolio'} />
              <Portfolio />
            </>
          }
        />
        <Route
          path="/digitalmarketing"
          element={
            <>
              <Header data={'digital-marketing'} />
              <DigitalMarketing/>
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <Header data={'contact'} />
              
              <Contact />
              {/* <GetInTouch/> */}
              
            </>
          }
        />

<Route
          path="/methodlogy"
          element={
            <>
              <Header data={'methodlogy'} />
              <Methodlogy />
            </>
          }
        />
      </Routes>
      <Footer />
      <BackToTop />
    </BrowserRouter>
  );
}

export default App;
