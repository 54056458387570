import React, { useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import RY4 from "../assets/img/ry4.webp";

function Footer() {
  const currentYear = new Date().getFullYear();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <section id="footer" className="footer">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <Link to="/" className="logo d-flex align-items-center">
                {/* <img src={RY4} alt="" /> */}
              </Link>
              <p>Transform your enterprise with our innovative services designed to streamline 
                your operations and propel you into the digital age. Embrace a new way of doing 
                business with tailored solutions that drive efficiency, enhance productivity, 
                and deliver unmatched value.
              </p>
              <div className="social-links d-flex mt-4">
                <Link to="https://x.com/ryientsoft?t=yfN4uoxXyNn-THlrFP6RtQ&s=08" target="_blank" className="twitter"><i className="bi bi-twitter-x"></i></Link>
                <Link to="https://www.facebook.com/share/Kd7LPz8WXxxdyLEP/?mibextid=qi2Omg" target="_blank" className="facebook"><i className="bi bi-facebook"></i></Link>
                <Link to="https://www.instagram.com/ryientsoft_official?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" className="instagram"><i className="bi bi-instagram"></i></Link>
                <Link to="https://www.linkedin.com/company/ryientsoft-technologies/mycompany/" className="linkedin"><i className="bi bi-linkedin"></i></Link>
              </div>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><Link className='text-decoration-none' to="/">Home</Link></li>
                <li><Link className='text-decoration-none' to="/about">About us</Link></li>
                <li><Link className='text-decoration-none' to="/domainservices">Domain services</Link></li>
                <li><Link className='text-decoration-none' to="/portfolio">Portfolio</Link></li>
                <li><Link className='text-decoration-none' to="/digitalmarketing">Digital marketing</Link></li>
              </ul>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Our Services</h4>
              <ul>
            <li class='text-decoration-none'>Web Design</li>
            <li class='text-decoration-none'>Mobile App Design</li>
            <li class='text-decoration-none'>Enterprise Application Design</li>
            <li class='text-decoration-none'>Storyboard & Animation Design</li>
            <li class='text-decoration-none'>Mobile App Design</li>
          </ul>
            </div>

            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4 className='footer-he' style={{marginTop:"-14px"}}>Contact Us</h4>
              <p className='footer-p' style={{marginTop:"-15px", fontWeight:"bold",fontSize:"15px"}}>
              <Link  className='text-decoration text-white'
                  to="https://www.google.com/maps/place/28%C2%B037'36.0%22N+77%C2%B022'40.6%22E/@28.6266667,77.3779444,17z/data=!3m1!4b1!4m4!3m3!8m2!3d28.6266667!4d77.3779444?entry=ttu"
                  target="_blank">H96, BS-7, Sector-63, Noida, Uttar Pradesh, 201301
                  </Link>
                <br />
                <strong>Email: </strong><Link className='text-decoration-none text-white' to="mailto:info@ryientsoft.com">info@ryientsoft.com</Link>
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <div className="copyright">
            &copy; Copyright <strong><span>RyientSoft Technologies</span></strong> 2019-{currentYear}
          </div>
          <div className="credits">
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
