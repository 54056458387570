import React from 'react'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact } from '@fortawesome/free-brands-svg-icons'; 
import { faJs } from '@fortawesome/free-brands-svg-icons'; 
import python1 from '../assets/img/Python1.png'
import react from '../assets/img/React.webp'
import js from '../assets/img/js.webp'
import html from '../assets/img/HTML5.png'
import ruby from '../assets/img/ruby.webp'
import flutter from '../assets/img/f-removebg-preview.png'
import aws from '../assets/img/general-aws-cloud.512x512-removebg-preview.png'
import sql from '../assets/img/sql1.png'
import django from '../assets/img/dj.png'
import cloud from '../assets/img/azure-icon-removebg-preview.png'
import rails from '../assets/img/rails.png'
import next from '../assets/img/nextjs.png'
import bootstrap from '../assets/img/bootstarp1.png'
import node from '../assets/img/node1.png'
import ios from '../assets/img/app-removebg-preview.png'
import android from '../assets/img/android-logo-removebg-preview.png'
import mongo from '../assets/img/mongo-removebg-preview.png'
import post from '../assets/img/postgresql-removebg-preview.png'
import sqlite from '../assets/img/sqlitebrowser-icon-removebg-preview.png'
import red from '../assets/img/redhat_logo_icon_168023-removebg-preview.png'
import oracle from '../assets/img/oracle-removebg-preview.png'






function Services() {
  return (
    <>
      <section id="services" className="services sections-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2 >Domain Services</h2>
            <p>Digital services and IT consulting of the next generation enable firms to use robust products and innovative solutions that uncover growth opportunities and drive business transformation.
            </p>
          </div>

          <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">
            <div className="col-lg-4 col-md-6">
              <div className="service-item ">
                <div className="icon">
                  <i className="bi bi-code-slash"></i>
                </div>
                <h3 >Web Design</h3>
                <p>Custom web designing to enhance your online presence and grow your bottom-line. We create visually stunning and user-friendly websites tailored to your business needs. Our team ensures your website is optimized for performance and scalability.</p>
                {/* <Link to="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="service-item position-relative">
                <div className="icon">
                  <i className="bi bi-android2"></i>
                </div>
                <h3 >Mobile App Design</h3>
                <p>Hybrid and native apps bringing high efficiency, responsiveness, and superb functionality. Our designs ensure seamless user experiences across all devices and platforms. We prioritize intuitive navigation and engaging interfaces to captivate your audience.</p>

                {/* <Link to="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="service-item position-relative">
                <div className="icon">
                  <i className="bi bi-braces"></i>
                </div>
                <h3 >Branding & Identity Design</h3>
                <p>Embedding elements that define brand personality and emotional appeal for users. We craft unique brand identities that resonate with your target audience. Our strategy involves comprehensive market research and creative execution.</p>
                {/* <Link to="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="service-item position-relative">
                <div className="icon">
                  <i className="bi bi-file-earmark-code-fill"></i>
                </div>
                <h3 >Product Design</h3>
                <p>Focused approach to concept design, functions, and customer requirements. Our team ensures your product stands out with innovative features and aesthetics. We blend functionality with visual appeal to create market-ready products.</p>
                {/* <Link to="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="service-item position-relative">
                <div className="icon">
                  <i className="bi bi-android"></i>
                </div>
                <h3 >Enterprise Application Design</h3>
                <p>Scalable architecture and design for information flow across organizational levels. We develop robust applications to support your business operations and growth. Our solutions enhance productivity and streamline complex processes.</p>
                {/* <Link to="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="service-item position-relative">
                <div className="icon">
                  <i className="bi bi-file-code-fill"></i>
                </div>
                <h3 >Storyboard & Animation Design</h3>
                <p className='text-justify'>Explaining ideas with web animations, visual communication, and project illustrations. We create compelling storyboards to visualize your concepts effectively. Our animations bring your ideas to life, making them more engaging and memorable.</p>
                {/* <Link to="#" className="readmore stretched-link">Read more <i className="bi bi-arrow-right"></i></Link> */}
              </div>
            </div>
          </div>
        </div>



        <div className="container" data-aos="fade-up">
          <div className="section-header1">
            <h2>Technologies</h2>
          </div>

          <div className="section-lang1">
           <h3>Frontend</h3>
          </div>

          <div className="row gy-4  " data-aos="fade-up" data-aos-delay="100"  >
            <div className="col-lg-3 col-md-6 ">
              <div className="service-item  position-relative">
                <div className="icon-skills1">
                  
                <img src={react} alt='/' >
                </img>
                 
                </div>
                <h3 className='language1'>React.Js</h3>
              </div>
            </div>
          

            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
                <div className="icon-skills1">
                  <img src={bootstrap} alt='/' >
                  </img>
                </div>
                <h3 className='language1'>BootStrap</h3>

              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={js} alt='/' >
                </img>
                </div>
                <h3 className='language1' >JavaScripts</h3>
              </div>
            </div>

           <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={html} alt='/' >
                </img>
                </div>
                <h3 className="language1" >Html</h3>
                </div>
            </div>
            
            <div className="section-lang1">
              <h3>Backend</h3>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={rails} alt='/' >
                </img>
                </div>
                <h3 className='language1'>Rails</h3>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={python1} alt='/' >
                </img>   
              </div>
                <h3 className='language1'>Python</h3>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={node} alt='/' >
                </img>
                </div>
                <h3 className='language1'>Node.JS</h3>
              </div>
            </div>
            
            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={django} alt='/' >
                </img>
                </div>
                <h3 className='language1' >Django</h3>
              </div>
            </div>
            
            <div className="section-lang">
              <h3>Mobile App Development</h3>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={react} alt='/' >
                </img>
                </div>
                <h3 className='language1' >React-Native</h3>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={flutter} alt='/' >
                </img>
                </div>
                <h3 className='language1' >Flutter</h3>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={ios} alt='/' >
                </img>
                </div>
                <h3 className='language1'>IOS</h3>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={android} alt='/' >
                </img>
                </div>
                <h3 className='language1'>Android</h3>
              </div>
            </div>

            <div className="section-lang1">
              <h3>DataBase</h3>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={sql} alt='/' >
                </img>
                </div>
                <h3 className='language1'>SQL</h3>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={mongo} alt='/' >
                </img>
                </div>
                <h3 className='language1'>Mongo DB</h3>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={post} alt='/' >
                </img>
                </div>
                <h3 className='language1'>Postgre SQL</h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={sqlite} alt='/' >
                </img>
                </div>
                <h3 className='language1'>SQLite</h3>
              </div>
            </div>

            <div className="section-lang1">
              <h3>Cloud Services</h3>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={aws} alt='/' >
                </img>
                </div>
                <h3 className='language1'>AWS</h3>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={cloud} alt='/' >
                </img>
                </div>
                <h3 className='language1'>Microsoft Azure.</h3>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={red} alt='/' >
                </img>
                </div>
                <h3 className='language1'>RedHat</h3>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="service-item position-relative">
              <div className="icon-skills1">
                <img src={oracle} alt='/' >
                </img>
                </div>
                <h3 className='language1'>Oracle Cloud</h3>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default Services